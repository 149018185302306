body
{
	overflow-x:hidden;
}
h1,h2,h3
{
    display:block;
}

.post_test
{
    text-align: left;
}
.post_test li
{
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}


input[type=radio] {
    margin-right: 5px;
}

.socialsignupbtnfb {
    border:1px solid #0067b9;
}

.course-label
    {
        height: 26px;
    }
.topic_tag
{
    border: 1px solid #efefef;
    padding:1px 5px;
    border-radius: 15px;
    background: #d5e9ff;
    font-size: 14px;
}
.topic_tag_type
{
    font-size: 13px;
    text-transform: uppercase;
}
.priamry-reading-sec
{
	border:0px solid #efefef;
}

.txt-sec-color
{
	color:#fff !important;
	font-size:16px;
	font-family: 'PTRoot','Open Sans';
}
.txt-sec-color:hover
{
	color:#62CBD9 !important;
}

.nav-tabs .nav-link {
    border: 1px solid #efefef;
    
}

.nav-link {
    
    margin-right: 4px;
}

.mobile-language-switch
	{
	display:none;
	}

.row {
   
    margin-right: -18px;
    margin-left: -18px;
}



.carousel-control-next, .carousel-control-prev {
   
    width: 5%;
   
}


.form-row>.col, .form-row>[class*=col-] {
    padding-right: 3px;
    padding-left: 3px;
}
.media-card-img-top {
	object-fit: cover;
	height:200px;

}
		

#printarea { width: 1110px !important; height:900px !important; }

.nav-alert
{
	padding-top:5px;
	background:#ffdfd5;
}

.survey-form-sq
{
	background: #efefef;
    padding: 15px 10px;
    margin: 3px;
}

.small
{
	font-size: 13px;
}
.zmdi 
{
	color:#fff;
}
.btn-enroll
{
	border: 2px solid #FF9800 !important;
	border-radius: 7px;
	
}
.page_next
{
	background: #62CBD9;
    color: #fff;
    padding: 6px 12px;
}
.page_prev {
   background: #bdbdbd;
    color: #fff;
    padding: 6px 12px;
}

.topic_next
{
	background: #ffc107;
    color: #fff;
    padding: 6px 12px;
}
.tipicsecleft
{
	background:#efefef;
}
.ct_resources
{
	text-align:right;
}

.cbtn_sec
{
	background: #62CBD9;
    color: #fff;
    padding: 6px 12px;
}

.cbtn_primary
{
	background: #ffc107;
    color: #fff;
    padding: 6px 12px;
}

.course_name_topic_page
{
	background: #ffc107;
    padding: 2px 2px;
    color: #000 !important;
    font-weight: 700;
}

.course_topic_heading
{
	font-size:32px;
	padding:0px;
}

.txt-large
{
	font-size:24px;
	font-family: 'HelveticaNeue';
}
.btn-start 
{
	color:#fff;
	background:#17a2b8;
	padding:6px 10px;
	text-transform:Capitalize;
}

.btn-start-light 
{
	color:#000;
	background:#fff;
	padding:6px 10px;
	text-transform:Capitalize;
}

.btn-start 
{
	color:#fff !important;
}
.header-configure-area li
{
	list-style:none;
}

.fab
{
	    font-family: Arial;
}
.resend_email_activation
{
	color:#FFEB3B;
	font-size:14px;
}

.video-container {
    overflow: hidden;
    position: relative;
    width:70%;
	margin-left:auto;
	margin-right:auto;
}
.space-pt_25 {
    margin-top: 25px;
}


.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-container-home {
    overflow: hidden;
    position: relative;
    width:98%;
	margin-left:auto;
	margin-right:auto;
	
}
.video-container-home::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container-home iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.form-group {
    margin-bottom: 0.9rem;
}
.brcheading
{
	padding-top:10px;
}
.true
{
    color:#28a745;
}
.online-course-app
{
	background:#fff;
	padding-top:0px;
}
strong
{
    font-weight: 700;
    font-family: 'PTRootUIMedium';
}
.txtBG
{
    background: #fcaf17;
    padding: 1px 8px;
    color: #000;
    font-size: 29px;
}
.txtBGs
{
    background: #fcaf17;
    padding: 1px 5px;
    color: #000;
   
}
.ref a
{
padding:0px;
}
.txtBox
{
    border: 1px solid #d2d6da;
    padding: 10px;
    margin-bottom: 20px;
    background: #f5f5f5;
}
.txtBoxFacilitator
{
    border: 1px solid #d2d6da;
    padding: 10px;
    margin-bottom: 20px;
    background: #ffffff;
}
sup {
    top: -0.6em;
    font-size: 12px;
    color: #adadad;
}

.btn-secondary
{
    background:#62cbd9;
}
.btn-primary
{
    background:#fcaf17;
    border:0px solid #f3a305;
	box-shadow: 0 2px 0 #eaa51d;
	border-radius: 3px;
	font-size: 20px;
}
.btn-primary:hover {
    color: #fff !important;
    background-color: #000;
    border-color: #000;
}

.small, small {
    font-size: 12px;
   
}
.certbody
{
    overflow-x: auto;
}
.panel-title
{
    margin-top:5px;
}
.shortDesc
{
    font-size:13px;
    color: #565656;
}
.txtLight
{
    color:#b7b7b7;
}
.signup
{
    background: #fff;
   padding: 15px 20px;
    border: 1px solid #f7f1d9;
	color:#565656;
	padding-bottom:0px;
   
}
.signup-popup
{
    background: #fff;
   padding: 15px 20px;
    border: 1px solid #f7f1d9;
	color:#565656;
	padding-bottom:0px;
    height: 90vh;
     overflow: auto;
}

.question-container label
{
    padding-left:5px;
}

.ratingbox
{
    border:0px solid #000;
    padding:20px;
    min-height: 330px;
    margin-bottom: 20px;
}
.socialsignupbtnfb
{
background: #007bff;

}
.coreissueh3
{
    min-height: 75px;
}
.card-round a:hover
{
    text-decoration: none;
}
.tn-left a
{
    color:#efefef;
}

.home-bg { 
    background: #f4efe9 url('https://educately-resources.s3.ap-south-1.amazonaws.com/web-assets/headerbg.jpg') no-repeat right top ;
    padding-top:30px;
    min-height: 490px;
    border-bottom: 1px solid #eae6e1;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.7);
    color: #fff;
    font-size: 19px;  
}
.social-instagram
{
    background: #efefef;
    color: #000 !important;
}
.social-print
{
    background: #ffc107;
    color: #fff !important;
}

.bglight2
{
    background: #f8f8ff;
}

.home-intro-text h6 {
    color: #777777;
    font-weight: 500;
    margin-bottom: 20px;
    font-size:18px;
}
.home-intro-text h1 {
    font-size: 56px;
    color: #333333;
   
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.41;
}

.navbar-nav
{
    text-transform: uppercase;
    font-size:0.80em;
    font-family: 'Helvetica';
}
.sign-in
{
    font-size: 0.8em;
}


.content-light
{
	background:#f3f4f6;
}

.register-bg
{
	background:#f3f4f6 url('https://educately-resources.s3.ap-south-1.amazonaws.com/web-assets/signup-bg.png');
}

.content-blue
{
	background:#0f858e;
}
.text-white h2
{
    color:#fff;
}
.topic figure
{
	padding:30px;
}
.topic figcaption
{
	text-align:center;
	font-size:20px;
}

.courseTopic
{
    background: #ecf0f1;
    border:1px solid #efefef;
}

.certifcate
{
    padding-top:20px;
    font-family: 'AdelleBook';
}
.color-dark
{
    color:#000;
}

.Completed
{
   background: #f9f9f9;
}
.icon-Completed
{
    color:#20c997;
}

.partners img
{
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.partners img:hover
{
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
}



article
{
    border-bottom: 1px solid #efefef;
}


.topic figure > img {

    height: 213px;
	
}

.navbar-brand {
   
    padding:0px;
   
}

.label { border-radius: 0; text-shadow: none; font-size: 11px; font-weight: normal; padding: 3px 5px 3px; background-color: #abbac3!important }
.label[class*="span"][class*="arrow"] { min-height: 0 }
.badge {text-shadow: none;
font-size: 12px;
padding: 1px 15px;
font-weight: normal;
line-height: 15px;
background-color: #ABBAC3!important; }
.label-transparent,
.badge-transparent { background-color: transparent!important }
.label-grey,
.badge-grey { background-color: #a0a0a0!important }
.label-info,
.badge-info { background-color: #3a87ad!important }
.label-primary,
.badge-primary { background-color: #fcaf17!important }
.label-success,
.badge-success { background-color: #82af6f!important;color:#fff; }
.label-important,
.badge-important { background-color: #d15b47!important;color:#fff; }
.label-inverse,
.badge-inverse { background-color: #333!important }
.label-warning,
.badge-warning { background-color: #f89406!important }
.label-pink,
.badge-pink { background-color: #d6487e!important }
.label-purple,
.badge-purple { background-color: #9585bf!important }
.label-yellow,
.badge-yellow { background-color: #fee188!important }
.label-light,
.badge-light { background-color: #e7e7e7!important }
.badge-yellow,
.label-yellow { color: #963!important; border-color: #fee188 }
.badge-light,
.label-light { color: #888!important }
.label.arrowed,
.label.arrowed-in { position: relative; margin-left: 9px }
.label.arrowed:before,
.label.arrowed-in:before { display: inline-block; content: ""; position: absolute; left: -14px; top: 0; border: 9px solid transparent; border-width: 9px 7px; border-right-color: #abbac3 }
.label.arrowed-in:before { border-color: #abbac3; border-left-color: transparent!important; left: -9px }
.label.arrowed-right,
.label.arrowed-in-right { position: relative; margin-right: 9px }
.label.arrowed-right:after,
.label.arrowed-in-right:after { display: inline-block; content: ""; position: absolute; right: -14px; top: 0; border: 9px solid transparent; border-width: 9px 7px; border-left-color: #abbac3 }
.label.arrowed-in-right:after { border-color: #abbac3; border-right-color: transparent!important; right: -9px }
.label-info.arrowed:before { border-right-color: #3a87ad }
.label-info.arrowed-in:before { border-color: #3a87ad }
.label-info.arrowed-right:after { border-left-color: #3a87ad }
.label-info.arrowed-in-right:after { border-color: #3a87ad }
.label-primary.arrowed:before { border-right-color: #2283c5 }
.label-primary.arrowed-in:before { border-color: #2283c5 }
.label-primary.arrowed-right:after { border-left-color: #2283c5 }
.label-primary.arrowed-in-right:after { border-color: #2283c5 }
.label-success.arrowed:before { border-right-color: #82af6f }
.label-success.arrowed-in:before { border-color: #82af6f }
.label-success.arrowed-right:after { border-left-color: #82af6f }
.label-success.arrowed-in-right:after { border-color: #82af6f }
.label-warning.arrowed:before { border-right-color: #f89406 }
.label-warning.arrowed-in:before { border-color: #f89406 }
.label-warning.arrowed-right:after { border-left-color: #f89406 }
.label-warning.arrowed-in-right:after { border-color: #f89406 }
.label-important.arrowed:before { border-right-color: #d15b47 }
.label-important.arrowed-in:before { border-color: #d15b47 }
.label-important.arrowed-right:after { border-left-color: #d15b47 }
.label-important.arrowed-in-right:after { border-color: #d15b47 }
.label-inverse.arrowed:before { border-right-color: #333 }
.label-inverse.arrowed-in:before { border-color: #333 }
.label-inverse.arrowed-right:after { border-left-color: #333 }
.label-inverse.arrowed-in-right:after { border-color: #333 }
.label-pink.arrowed:before { border-right-color: #d6487e }
.label-pink.arrowed-in:before { border-color: #d6487e }
.label-pink.arrowed-right:after { border-left-color: #d6487e }
.label-pink.arrowed-in-right:after { border-color: #d6487e }
.label-purple.arrowed:before { border-right-color: #9585bf }
.label-purple.arrowed-in:before { border-color: #9585bf }
.label-purple.arrowed-right:after { border-left-color: #9585bf }
.label-purple.arrowed-in-right:after { border-color: #9585bf }
.label-yellow.arrowed:before { border-right-color: #fee188 }
.label-yellow.arrowed-in:before { border-color: #fee188 }
.label-yellow.arrowed-right:after { border-left-color: #fee188 }
.label-yellow.arrowed-in-right:after { border-color: #fee188 }
.label-light.arrowed:before { border-right-color: #e7e7e7 }
.label-light.arrowed-in:before { border-color: #e7e7e7 }
.label-light.arrowed-right:after { border-left-color: #e7e7e7 }
.label-light.arrowed-in-right:after { border-color: #e7e7e7 }
.label-grey.arrowed:before { border-right-color: #a0a0a0 }
.label-grey.arrowed-in:before { border-color: #a0a0a0 }
.label-grey.arrowed-right:after { border-left-color: #a0a0a0 }
.label-grey.arrowed-in-right:after { border-color: #a0a0a0 }
.label-large { font-size: 15px; padding: 3px 8px 4px }
.label-large.arrowed,
.label-large.arrowed-in { margin-left: 12px }
.label-large.arrowed:before,
.label-large.arrowed-in:before { left: -16px; border-width: 11px 8px }
.label-large.arrowed-in:before { left: -12px }
.label-large.arrowed-right,
.label-large.arrowed-in-right { margin-right: 11px }
.label-large.arrowed-right:after,
.label-large.arrowed-in-right:after { right: -16px; border-width: 11px 8px }
.label-large.arrowed-in-right:after { right: -12px }

.coursedesc
{
	color:#888888;
}

.tags a{border:1px solid #DDD;display:inline-block;color:#717171;background:#FFF;-webkit-box-shadow:0 1px 1px 0 rgba(180,180,180,0.1);box-shadow:0 1px 1px 0 rgba(180,180,180,0.1);-webkit-transition:all .1s ease-in-out;-moz-transition:all .1s ease-in-out;-o-transition:all .1s ease-in-out;-ms-transition:all .1s ease-in-out;transition:all .1s ease-in-out;border-radius:2px;margin:0 3px 6px 0;padding:5px 10px}
.tags a:hover{border-color:#08C;}
.tags a.primary{color:#FFF;background-color:#428BCA;border-color:#357EBD}
.tags a.success{color:#FFF;background-color:#5CB85C;border-color:#4CAE4C}
.tags a.info{color:#FFF;background-color:#5BC0DE;border-color:#46B8DA}
.tags a.warning{color:#FFF;background-color:#F0AD4E;border-color:#EEA236}
.tags a.danger{color:#FFF;background-color:#D9534F;border-color:#D43F3A}


.sarticle img
{
max-width: 475px;
padding:10px 25px 10px 10px;
}
.course-title-sec
{
	min-height:178px;
}
.content-white
{
	background: #fff;
}
.discussion
{
	margin-bottom:20px;
	background:#fff;
	
	border-radius: 20px;
}
.discussion h2
{	
	text-align: center;
	padding: 10px;
}

.discussion h5
{
    color:#06386d
}


video {
	width: 100%    !important;
	height: auto   !important;
	text-align: center;
margin: 0 auto;
padding-bottom:30px;
  }
  
  .vide-sec
  {
	  margin-bottom:20px;
  }


  .center {
    margin-left: auto;
    margin-right: auto;
	display: block;
	background: #fff;
}

.color-black
{
    color:#000000;
}
.m-25px-b
{
    margin-bottom: 25px;
}

.p-25px-t
{
    padding-top: 25px;
}


.accordion-toggle
{
	font-size: 18px;
}

.content-white
{
	background: #fff;
}

.content {
    padding: 45px 0px;
    min-height: 400px;
    border-top: 0px solid #ece6de;
}
.d-none
{
    display: none;
}
.capitalize
{
    text-transform: capitalize;
}


.panel-body ul, ol
{
  
}

.panel-body p2
{
	background: #f2f3ff;
    padding: 9px;
    font-size: 14px;
    border: 1px solid #dfe0e6;
    border-radius: 12px;
}

.course-discussion-board
{
	padding:15px;
}

.course-discussion-board p
{
	background: #f2f3ff;
    padding: 9px;
    font-size: 14px;
    border: 1px solid #dfe0e6;
    border-radius: 12px;
}

.course-discussion-board ul, ol
{
  font-size:14px;
  font-weight: 500;
  margin-left: 15px;
}
.quizPrompt
{
	text-align: center;
	font-weight: 700;
	z-index: 10;
	position: absolute;
	width: 50%;
   
    color: #000;
  
	padding-top: 50px;
	
left:0;
right:0;
margin-left:auto;
margin-right:auto;
margin-top:25px;
background: #fff;
border-radius: 10px;
border: 4px solid #8eb72c;

}

.quizBox
{
	border:1px solid #efefef;
	padding:10px;
	background: #fbfdff;
}

.quizBox h1, h2, h3
{
		margin-top: 10px;
		margin-bottom: 0px;
}

.color-red
{
    color:#ff0000;
}

.color-green
{
    color:#4CAF50;
}

.small
{
	line-height: 1.4;
}

.quizFailMsg
{
	padding: 5px;
    color: #f49f56;
    font-size: 15px;
}

.mkdf-course-features-holder .mkdf-course-features {
	list-style: none;
}
.lnr {
    font-family: 'Linearicons-Free';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.widget.mkdf-course-features-widget .mkdf-course-features li .mkdf-item-icon {
    color: #40c4ff;
    font-size: 18px;
    margin-right: 15px;
}

.widget.mkdf-course-features-widget .mkdf-course-features li {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 0;
}


.course-features li {
    border-bottom: 1px solid #ebebeb;
	padding: 10px 0;
	list-style: none;
}

.course-features
{
	margin-top:12px;
	padding-top:20px;
	padding-bottom:20px;
	background: #f7f7f7;
	border-radius: 17px;
	border: 1px solid #f1f1f1;
	
	
}
.course-features-ul
{
	margin-left: -30px;
}
.course-features-title
{
	margin-bottom: 10px;
	text-align: center;
}

.course-features ul
{
	list-style: none;
}

.mkdf-item-label {
    font-weight: 600;
    font-size: 14px;
    color: #303030;
}

.mkdf-item-value {
    float: right;
    font-size: 14px;
}

.Completed
{
	background: #fdfdfd;
}
.Completed p
{
	color: #dadada;
}

.red-text
{
	color:#F97307;
	
	background: none;
	text-align: center;
	font-size: 18px;
}
.teal-text a
{
	background:none !important;
}
.dnone
{display:none;}
.heading
{
	padding-top: 15px;
	padding-bottom:15px;
	/* font-family: 'Roboto', 'Helvetica Neu'; */
	font-size: 30px;
    color: #3e3e3e;
    font-weight: 600;
}
.outline
{
   
    background: #fff;
	padding: 15px;
	border-radius: 5px;
}

.outline ul
{
	list-style: none;
	margin-left: -10px;
}

.outline li
{
	padding:7px;
	border-bottom: 1px solid #efefef;
}

.navbar {
 
    margin-bottom: 12px;
  
}



.myCourses
{
    background: #62CBD9;
    padding: 1px 8px;
    border-radius: 5px;
    color: #fff;
}

.carousel-inner {
   
    height: 635px;
}

.navbar-collapse {
    padding-right: 0px;
}

.img-fluid {
  
    padding-top: 10px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #f2f4f5;
    border-radius: .25rem;
    margin-bottom: 15px;
}
.card a:hover
{
background: #000;
}
.label-primary, .badge-primary
{
	background:#fcaf17;
}
.carousel {
    overflow: hidden;
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.card-header {
    padding: .45rem 1.25rem;
    margin-bottom: 0;
    background-color:#fdfdfd;
    border-bottom: 1px solid #eaeaea !important;
	color: #000;
    font-size: 16px;
}
.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.0rem;
	background:#fbfbfb;
}




.video-card
{
	padding:5px !important;
	
}



.divider-text {
    position: relative;
    text-align: center;
   margin-top: 5px;
    margin-bottom: 5px;
}
.divider-text span {
    padding: 8px;
    font-size: 13px;
    position: relative;   
    z-index: 2;
}
.divider-text:after {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px solid #ddd;
    top: 55%;
    left: 0;
    z-index: 1;
}
.bg-light
{
	background: #fff;
}

.content-yellow
{
	background: #fcaf17;
}
.color-yellow
{
    color: #fcaf17;
}
.btn-facebook {
    background-color: #405D9D;
	color: #fff;
	width:130px;
}
.btn-twitter {
    background-color: #42AEEC;
	color: #fff;
	width:130px;
}
.btn-gmail {
    background-color: #db4b3a;
	color: #fff;
	width:130px;
}


/*process-box*/
body{
    
}
.process-box{
    background: #fff;
    padding: 10px;
    border-radius: 15px;
    position: relative;
    box-shadow: 2px 2px 7px 0 #00000057;
}
.process-left:after{
        content: "";
    border-top: 15px solid #ffffff;
    border-bottom: 15px solid #ffffff;
    border-left: 15px solid #ffffff;
    border-right: 15px solid #ffffff;
    display: inline-grid;
    position: absolute;
    right: -15px;
    top: 42%;
    transform: rotate(45deg);
    box-shadow: 3px -2px 3px 0px #00000036;
    z-index: 1;
}
.process-right:after{
        content: "";
    border-top: 15px solid #ffffff00;
    border-bottom: 15px solid #ffffff;
    border-left: 15px solid #ffffff;
    border-right: 15px solid #ffffff00;
    display: inline-grid;
    position: absolute;
    left: -15px;
    top: 42%;
    transform: rotate(45deg);
    box-shadow: -1px 1px 3px 0px #0000001a;
    z-index: 1;
}
.process-step{
    background: #00BCD4;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    color: #fff;
    height: 100%;
    padding-top: 8px;
    position: relative;
    top: -26px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: -6px 8px 0px 0px #00000014;
}
.process-point-right{
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #00bcd4;
    box-shadow: 0 0 0px 4px #5c5c5c;
    margin: auto 0;
    position: absolute;
    bottom: 40px;
    left: -63px;
}
.process-point-right:before{
    content: "";
    height: 144px;
    width: 11px;
    background: #5c5c5c;
    display: inline-grid;
    transform: rotate(36deg);
    position: relative;
    left: -50px;
    top: -0px;
}
.process-point-left{
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #00bcd4;
    box-shadow: 0 0 0px 4px #5c5c5c;
    margin: auto 0;
    position: absolute;
    bottom: 40px;
    right: -63px;
}
.process-point-left:before {
    content: "";
    height: 144px;
    width: 11px;
    background: #5c5c5c;
    display: inline-grid;
    transform: rotate(-38deg);
    position: relative;
    left: 50px;
    top: 0px;

}

.process-last:before{
    display: none;
}
.process-box p{
    z-index: 9;
}
.process-step p{
    font-size: 20px;
}
.process-step h2{
    font-size: 39px;
}
.process-step:after{
    content: "";
    border-top: 8px solid #04889800;
    border-bottom: 8px solid #048898;
    border-left: 8px solid #04889800;
    border-right: 8px solid #048898;
    display: inline-grid;
    position: absolute;
    left: -16px;
    top: 0;
}
.process-step:before{
    content: "";
    border-top: 8px solid #ff000000;
    border-bottom: 8px solid #048898;
    border-left: 8px solid #048898;
    border-right: 8px solid #ff000000;
    display: inline-grid;
    position: absolute;
    right: -16px;
    top: 0;
}
.process-line-l{
    background: none;
    height: 4px;
    position: absolute;
    width: 136px;
    right: -153px;
    top: 64px;
    z-index: 9;
}
.process-line-r{
    background: none;
    height: 4px;
    position: absolute;
    width: 136px;
    left: -153px;
    top: 63px;
    z-index: 9;
}


.round-button
{
    border-radius: 5px;
    padding: 11px 13px;
}
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 5px 0;
    border-radius: 4px;
}
.dark-link
{
    color:#f97307;
}


.checkout-wrapper{padding-top: 40px; padding-bottom:40px; background-color: #fafbfa;}
.checkout{    background-color: #fff;
    border:1px solid #eaefe9;
     
    font-size: 14px;}
.panel{margin-bottom: 0px;padding-left: 1px;}
.checkout-step {
     
    border-top: 1px solid #f2f2f2;
    color: #666;
    font-size: 14px;
    padding: 30px;
    position: relative;
}

.checkout-step-number {
    border-radius: 50%;
    border: 1px solid #666;
    display: inline-block;
    font-size: 12px;
    height: 32px;
    margin-right: 26px;
    padding: 6px;
    text-align: center;
    width: 32px;
}
.checkout-step-title{ font-size: 18px;
    font-weight: 500;
    vertical-align: middle;display: inline-block; margin: 0px;
     }
 
.checout-address-step{}
.checout-address-step .form-group{margin-bottom: 18px;display: inline-block;
    width: 100%;}

.checkout-step-body{padding-left: 10px; 
    padding-top: 30px;}

.checkout-step-active{display: block;}
.checkout-step-disabled{display: none;}

.checkout-login{}
.login-phone{display: inline-block;}
.login-phone:after {
    content: '+91 - ';
    font-size: 14px;
    left: 36px;
}
.login-phone:before {
    content: "";
    font-style: normal;
    color: #333;
    font-size: 18px;
    left: 12px;
        display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.login-phone:after, .login-phone:before {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.login-phone .form-control {
    padding-left: 68px;
    font-size: 14px;
    
}
.checkout-login .btn{height: 42px;     line-height: 1.8;}
 
.otp-verifaction{margin-top: 30px;}
.checkout-sidebar{background-color: #fff;
    border:1px solid #eaefe9; padding: 30px; margin-bottom: 30px;}
.checkout-sidebar-merchant-box{background-color: #fff;
    border:1px solid #eaefe9; margin-bottom: 30px;}
.checkout-total{border-bottom: 1px solid #eaefe9; padding-bottom: 10px;margin-bottom: 10px; }
.checkout-invoice{display: inline-block;
    width: 100%;}
.checout-invoice-title{    float: left; color: #30322f;}
.checout-invoice-price{    float: right; color: #30322f;}
.checkout-charges{display: inline-block;
    width: 100%;}
.checout-charges-title{float: left; }
.checout-charges-price{float: right;}
.charges-free{color: #43b02a; font-weight: 600;}
.checkout-payable{display: inline-block;
    width: 100%; color: #333;}
.checkout-payable-title{float: left; }
.checkout-payable-price{float: right;}

.checkout-cart-merchant-box{ padding: 20px;display: inline-block;width: 100%; border-bottom: 1px solid #eaefe9;
 padding-bottom: 20px; }
.checkout-cart-merchant-name{color: #30322f; float: left;}
.checkout-cart-merchant-item{ float: right; color: #30322f; }
.checkout-cart-products{}

.checkout-cart-products .checkout-charges{ padding: 10px 20px;
    color: #333;}
.checkout-cart-item{ border-bottom: 1px solid #eaefe9;
    box-sizing: border-box;
    display: table;
    font-size: 12px;
    padding: 22px 20px;
    width: 100%;}
 .checkout-item-list{}
.checkout-item-count{ float: left; }
.checkout-item-img{width: 60px; float: left;}
.checkout-item-name-box{ float: left; }
.checkout-item-title{ color: #30322f; font-size: 14px;  }
.checkout-item-unit{  }
.checkout-item-price{float: right;color: #30322f; font-size: 14px; font-weight: 600;}


.checkout-viewmore-btn{padding: 10px; text-align: center;}

.header-checkout-item{text-align: right; padding-top: 20px;}
.checkout-promise-item {
    background-repeat: no-repeat;
    background-size: 14px;
    display: inline-block;
    margin-left: 20px;
    padding-left: 24px;
    color: #30322f;
}
.checkout-promise-item i{padding-right: 10px;color: #43b02a;}

.clr-blue
{
    color:#0056b3;
}
.clr-blue a:active
{
    color:#000;
}

.bold
{
    font-weight: 500;
}

.references
{
    margin-bottom:10px;
    border:1px solid #f9f7f6;
    padding:10px;
    background: #fdfcfb;
}

.faqs
{
    margin-bottom:10px;
    border:1px solid #f9f7f6;
    padding:15px 25px;
    background: #f6f3ee;
}
.tips
{
    background: #fff9e8;
    padding: 35px;
}
.summary
{
    padding:35px;
    margin-bottom: 20px;
    border: 1px solid #f9f9f9;
    background: #fff;
}
.faqheading
{
margin-top:30px;
}


.issue-item h4
{
    padding-top: 15px;
    font-weight: 400;
    font-size: 18px;
}

.article h1
{
    color:#0e3a69;
    font-weight: 500;
    font-size: 28px;
    padding-left:0px;
}
.tags a
{
    background:#f6f3ee;
}
.discussion h6 a
{
    color:#0e3a69;  
    font-size: 16px;
}

.comment-text
{
    font-size: 15px;
    color: #565656;
}
.comment-source
{
    color:#a5a5a5;
    font-size: 14px;
}

.discussion h5
{
    color:#06386d
}

.commentsec
{
    /* min-height: 400px; */
}
.mycomments
{
    background:#f6faff;
}
.btnDelete
{
    background: none;
}

.no-comments h5
{
    color: #097790 !important;
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    font-weight: 400;
}
h6
{
    font-size: 20px;;
}
.small
{
    font-size: 12px;
}

 ol { 
    line-height: 1.6;
    list-style-type: decimal;
    counter-reset: section;
    margin-left: 2rem;
    font-size: 17px;
  }
  .how-it-works li {
      counter-increment: section;
      margin-top: 2rem;
  }
  .how-it-works  li:before {
      content: counters(section,"");
      border: 2px solid #f5a22a;
      border-radius: 50%;
      display: inline-block;
      float: left;
      width: 3rem;
      height: 3rem;
      text-align: center;
      padding-top: .25rem;
      font-weight: 700;
      margin-left: -3.7rem;
      margin-right: 1rem;
      background: rgba(0,0,0,0.025);
      font-size: 22px;
  }
  .how-it-works h4
  {
      font-size:20px;
      font-weight: 500;
  }

  .how-it-works p
  {
    font-size:17px;
      font-weight: 300;
  }


  .steps {
    position: relative;
  }
  .steps:before {
    top: 125px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 2px;
    background-color: #ff4769;
    left: 50%;
    margin-left: -1px;
  }
  
  .timeline {
    margin: 0px auto;
    overflow: hidden;
    position: relative;
    padding: 0px;
    list-style-type: none;
  }
  .timeline .timeline-box {
    position: relative;
    float: left;
    clear: left;
    width: 45%;
    margin: 1em 2.5%;
    list-style-type: none;
    display: block;
    padding: 25px;
    background: #f4efe9;
    z-index: 15;
    border-radius: 5px;
  }
  
  .timeline .timeline-box .timeline-title {
    color: #3c3c3c;
    font-weight: 700;
    font-size: 1em;
    float: left;
    padding-left: 25px;
    padding-top: 8px;
  }
  .timeline .timeline-box .timeline-details {
    clear: both;
    padding-top: 5px;
  }
  .timeline .timeline-box span {
    position: absolute;
    top: -15px;
    right: 24px;
    font-size: 8em;
    font-weight: 700;
    color: #ff4769;
    opacity: 0.1;
    z-index: -1;
  }
  @media all and (max-width: 600px) {
    .timeline .timeline-box {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
	.popup-content
	{
		width:100% !important;
		overflow:auto !important;
	}
	.frm-popup
	{
	max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
	}
	.banner-cta
	{
		display:none;
	}
	
	.hide-in-mobile
	{
		display:none;
	}
	
	.mobile-language-switch
	{
	display:block;
	position: absolute;
    right: 47px;
    top: 33px;
    font-size: 14px;
    width: 100px;
    height: 30px;
    color: #19191a;
	text-align: right;
    z-index: 100;
    cursor: pointer;
    min-width: 180px;
	}
	
	.col2
	{
		width:50%;
		margin-bottom: 5px;
	}
	.col3
	{
		width:33%;
		margin-bottom: 5px;
	}
	
	.btn-social, .btn-social:hover, .btn-social:focus {
    width: 20px;
    line-height: 20px;
    font-size: 15px;
	}


	
  }
  .timeline .timeline-box:hover:after {
    background: #ff4769;
  }
  @media all and (min-width: 650px) {
    .timeline .timeline-box:after {
      display: block;
      content: ' ';
      height: 9px;
      width: 9px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      right: -6.55%;
      top: 1.5em;
      border: 2px solid #ff4769;
    }
  }
  .timeline .timeline-box:first-child {
    margin-bottom: 2.5em;
  }
  .timeline .timeline-box:nth-of-type(1n):before {
    position: absolute;
    top: 15px;
    right: -10px;
    display: inline-block;
    border-top: 10px solid transparent;
    border-left: 10px solid #f8f8f8;
    border-right: 0 solid #f8f8f8;
    border-bottom: 10px solid transparent;
    content: " ";
  }
  .timeline .timeline-box:nth-of-type(2n) {
    float: right;
    clear: right;
  }
  .timeline .timeline-box:nth-of-type(2n):before {
    right: auto;
    left: -10px;
    position: absolute;
    top: 15px;
    display: inline-block;
    border-top: 10px solid transparent;
    border-right: 10px solid #f8f8f8;
    border-left: 0 solid #f8f8f8;
    border-bottom: 10px solid transparent;
    content: " ";
  }
  @media all and (min-width: 600px) {
    .timeline .timeline-box:nth-of-type(2n) {
      margin-top: 3em;
    }
  }
  .timeline .timeline-box:nth-child(2n):after {
    left: -6.5%;
    right: auto;
  }
  
  .timeline-box:hover, .timeline-box:focus {
    transform: translate(0, -5px);
    -webkit-transform: translate(0, -5px);
    -ms-transform: translate(0, -5px);
  }
  
  .timeline-box:hover, .timeline-box:focus {
    -webkit-box-shadow: 0px 3px 0px 0px #ff4769;
    -moz-box-shadow: 0px 3px 0px 0px #ff4769;
    box-shadow: 0px 3px 0px 0px #ff4769;
  }
  
  .timeline-box {
    transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
  }
  .steps-area {

    padding: 85px 0 0px 0;

  }



  @import "//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.min.css";
@import "https://fonts.googleapis.com/css?family=Roboto:400,500";

.box > .icon { text-align: center; position: relative; }
.box > .icon > .image { position: relative; z-index: 2; margin: auto; width: 88px; height: 88px; border: 4px solid white; line-height: 88px; border-radius: 50%; background: #63B76C; vertical-align: middle; }
.box > .icon:hover > .image { background: #333; }
.box > .icon > .image > i { font-size: 36px !important; color: #fff !important; }
.box > .icon:hover > .image > i { color: white !important; }
.box > .icon > .info { margin-top: 24px; background: rgba(0, 0, 0, 0.04); border: 1px solid #e0e0e0; padding: 25px 0 10px 0; }
.box > .icon:hover > .info { background: rgba(0, 0, 0, 0.04); border-color: #e0e0e0; color: white; }
.box > .icon > .info > h3.title { font-family: "Roboto",sans-serif !important; font-size: 18px; color: #222; font-weight: 500; }
.box > .icon > .info > p {
    
    font-family: "Roboto",sans-serif !important; font-size: 13px; color: #666; line-height: 1.5em; margin: 20px;
 
}
.box > .icon:hover > .info > h3.title, .box > .icon:hover > .info > p, .box > .icon:hover > .info > .more > a { color: #222; }
.box > .icon > .info > .more a { font-family: "Roboto",sans-serif !important; font-size: 12px; color: #222; line-height: 12px; text-transform: uppercase; text-decoration: none; }
.box > .icon:hover > .info > .more > a { color: #fff; padding: 6px 8px; background-color: #428bca; }
.box .space { height: 30px; }


.two-line-ellipsis {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px; /* fallback */
    max-height: 75px; /* fallback */
    -webkit-line-clamp: 6; /* number of lines to show */
    -webkit-box-orient: vertical;


    
}



 
  .Issuecontent {
   
    display: none;
  }
  #loadMore {
    width: 150px;
   
    display: block;
    text-align: center;
    margin: 20px auto;
    padding: 10px;
    border-radius: 10px;
    font-family: "Roboto",sans-serif !important;
   border:2px solid #efefef;
  
    transition: .3s;
  }
  .noContent
  {
    display: none !important;
  }

  .blog-box {
    margin-top: 30px;
    box-shadow: 0px 8px 42px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0px 8px 42px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 8px 42px 0px rgba(0, 0, 0, 0.08);
    -o-box-shadow: 0px 8px 42px 0px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    position: relative;
}
.blog-box img {
    border-radius: 5px 5px 0 0;
}
.blog-box .blog-date {
    position: absolute;
    right: 15px;
    top: 0;
    background-color: #428bca;
    padding: 3px 8px;
    border-radius: 0 0 3px 3px;
    color: #fff;
    font-size: 12px;
}
.blog-box .blog-box-content {
    padding: 20px;
}
.blog-box .blog-box-content h4 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: 500;
}
.blog-box .blog-box-content h4 a {
    font-family: 'Prompt', sans-serif;
    color: #585858;
    line-height: 24px;
    text-decoration: none !important;
}
.blog-box .blog-box-footer {
    padding: 0 20px 20px 20px;
    text-align: right;
}
.btn-custom {
    background-color: #428bca;
}
.btn-sm {
    padding: 7px 24px;
    font-size: 12px;
}
.blogbtn {
    font-family: 'Prompt', sans-serif;
    color: #ffffff !important;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 10px 30px;
    border-radius: 20px;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}

@page {
	size: A4 landscape;
	max-height:100%;
	max-width:100%
	}

.lastest-post
{
    padding:15px 10px;
    border:1px solid #f7f7f7;
    background: #eaeaea;
    margin:0px;
    width:100%;
    min-height: 80px;
}

.signup-page-title
{
    font-size: 34px;
    font-weight: 300;
    text-align: center;
    color: #000;
    line-height: 1.5;
    margin: 0;
}
.signup-page-p
{
    color: #333e48;
    line-height: 1.5;
    margin: 0;
    text-align: center;
    font-family: National\ 2,Helvetica Neue,Helvetica,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
}
.signup-log-in
{
    font-weight: 600;
    color: #007FAA;
}

.or-bubble {
    background-color: #fff;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-weight: 500;
    overflow: show;
}
.or-divider {
    background-color: #fff;
    border-top: 0px;
    border-bottom: 1px solid #6B787F;
    height: 20px;
}

.reg-label
{
    margin-bottom: .1rem;
}

.reginput
{
    border-color: #D2D2D2;
    background: #efefef;
    border-radius: .0rem;
    height: 38px;
}



        .image-container {
            position: relative;
        }

        .image {
            opacity: 1;
            display: block;
            width: 100%;
            height: auto;
            transition: .5s ease;
            backface-visibility: hidden;
        }

        .middle {
            transition: .5s ease;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;
        }

        .image-container:hover .image {
            opacity: 0.3;
        }

        .image-container:hover .middle {
            opacity: 1;
        }
        .img-thumbnail
        {
            padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 80px;
    max-width: 100%;
    height: auto;
        }


.course-active-tab
{
    background: #000;
    padding: 8px;
    color:#fff !important;
}



/* readable cirriculumn html page */

  
  .previous {
  
    color: #fff;
  }
  
  .next {
   
    color: white;
  }

  .next-assesment {
    background-color: #007bff;
    color: white;
  }
  
  .round {
    border-radius: 50%;
  }
  .pagenav
  {
  
  }
  .jumbotron
  {
  padding: 2rem 1rem  1rem 2rem;
  }

  .jumbotron h3
  {
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .jumbotron h4
  {
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }




.panel-heading a:after {
    font-family:'Glyphicons Halflings';
    content:"\2212";
    float: right;
    color: grey;
}
.panel-heading a.collapsed:after {
    content:"\2b";
}

.panel-heading a:after {
  font-family:'Glyphicons Halflings';
  content:"\2212";
  float: right;
  color: grey;
}
.panel-heading a.collapsed:after {
  content:"\2b";
}

.ModuleTopic
{
    background: #fbfaf9;
    padding: 10px;
    border: 1px solid #f6f3ee;
    margin-bottom: 20px;
}

.panel-heading h4 a
{
    color:#000;
    font-weight: 700;
}



@import url(//fonts.googleapis.com/css?family=Lato:400,900);
@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);

.animate {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.info-card {
    width: 100%;
    border: 1px solid rgb(215, 215, 215);
    position: relative;
    font-family: 'Lato', sans-serif;
    margin-bottom: 20px;
    overflow: hidden;
    background: #f3f4f6;
    text-align: center;
    
}
.info-card > img {
    width: 100px;
    margin-bottom: 60px;
    width: 75% !important;
}
.info-card .info-card-details,
.info-card .info-card-details .info-card-header  {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: -100%;
    left: 0;
    padding: 0 15px;
    background: #f3f4f6;
    text-align: center;
}
.info-card .info-card-details::-webkit-scrollbar {
    width: 8px;
}
.info-card .info-card-details::-webkit-scrollbar-button {
    width: 8px;
    height: 0px;
}
.info-card .info-card-details::-webkit-scrollbar-track {
    background: transparent;
}
.info-card .info-card-details::-webkit-scrollbar-thumb {
    background: rgb(160, 160, 160);
}
.info-card .info-card-details::-webkit-scrollbar-thumb:hover {
    background: rgb(130, 130, 130);
}           

.info-card .info-card-details .info-card-header {
    height: auto;		
    bottom: 100%;
    padding: 10px 5px;
}
.info-card:hover .info-card-details {
    bottom: 0px;
    overflow: auto;
    padding-bottom: 25px;
    background: #f3f4f6;
}
.info-card:hover .info-card-details .info-card-header {
    position: relative;
    bottom: 0px;
    padding-top: 45px;
    padding-bottom: 25px;
    background: #f3f4f6;
    
}
.info-card .info-card-details .info-card-header h1,	
.info-card .info-card-details .info-card-header h3 {
    color: #3e3e3e;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0 !important;
    padding: 0 !important;
}
.info-card .info-card-details .info-card-header h3 {
    color: rgb(142, 182, 52);
    font-size: 15px;
    font-weight: 400;
    margin-top: 5px;
}
.info-card .info-card-details .info-card-detail .social {
    list-style: none;
    padding: 0px;
    margin-top: 25px;
    text-align: center;
}
.info-card .info-card-details .info-card-detail .social a {
    position: relative;
    display: inline-block;
    min-width: 40px;
    padding: 10px 0px;
    margin: 0px 5px;
    overflow: hidden;
    text-align: center;
    background-color: rgb(215, 215, 215);
    border-radius: 40px;
}



.whitebox{
    background-color:rgb(255,255,255);
    text-align:center;
    margin-bottom:30px;
    padding:20px;
  }
  .section-heading
  {
     
      font-size: 36px;
      color:#000;
  }
  
  
  

  


/* card details start  */
@import url('https://fonts.googleapis.com/css?family=Raleway:400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto+Condensed:400,400i,700,700i');
section{
    padding: 100px 0;
}
.details-card {
	background: #ecf0f1;
}

.card-content {
	background: #ffffff;
	
    border: 1px solid #efefef;
	box-shadow: 0 2px 3px 0 rgb(0 0 0 / 16%), 0 2px 8px 0 rgb(0 0 0 / 12%);
    padding-bottom: 10px;
}

.card-img {
	position: relative;
	overflow: hidden;
	border-radius: 0;
	z-index: 1;
}

.card-img img {
	width: 100%;
	height: auto;
    display: block;
    border: 0px solid #ececec;
    padding: 10px;
}

.card-img span {
	position: absolute;
    top: 15%;
    left: 12%;
    background: #1ABC9C;
    padding: 6px;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    transform: translate(-50%,-50%);
}
.card-img span h4{
        font-size: 12px;
        margin:0;
        padding:10px 5px;
         line-height: 0;
}
.card-desc {
	padding: 1.25rem;
   
}

.card-desc h3 {
	color: #000000;
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1.3em;
    margin-top: 0;
    margin-bottom: 5px;
    padding: 0;
}

.card-desc p {
	color: #747373;
    font-size: 14px;
	font-weight: 400;
	font-size: 1em;
	line-height: 1.5;
	margin: 0px;
	margin-bottom: 20px;
	padding: 0;
	font-family: 'Raleway', sans-serif;
}
.btn-card{
	background-color: #1ABC9C;
	color: #fff;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    padding: .84rem 2.14rem;
    font-size: .81rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    margin: 0;
    border: 0;
    -webkit-border-radius: .125rem;
    border-radius: 1.125rem;
    cursor: pointer;
    text-transform: uppercase;
    white-space: normal;
    word-wrap: break-word;
    color: #fff;
}
.btn-card:hover {
    background: orange;
}
a.btn-card {
    text-decoration: none;
    color: #fff;
}


.btn-loadMore{
	background-color: #1ABC9C;
	color: #fff;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    padding: .84rem 2.14rem;
    font-size: .81rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    margin: 0;
    border: 0;
    -webkit-border-radius: .125rem;
    border-radius: 1.125rem;
    cursor: pointer;
    text-transform: uppercase;
    white-space: normal;
    word-wrap: break-word;
    color: #fff;
}
.btn-loadMore:hover {
    background: orange;
}
a.btn-loadMore {
    text-decoration: none;
    color: #fff;
}

/* End card section */


.social-box .box{
    background: #FFF;
    border-radius: 10px; 
    padding: 25px 10px;
    margin: 20px 0px;
    cursor: pointer;
    transition: all 0.5s ease-out;
}

.social-box .box:hover{
   box-shadow: 0 0 9px #fff;
}

.social-box .box .box-text{
    margin:20px 0px;
    font-size: 15px;
    line-height: 30px;
}

.social-box .box .box-btn a{
    text-decoration: none;
    color: #4183D7;
    font-size: 16px;
}


.col-center {
	margin: 0 auto;
	float: none !important;
}
.carousel-t {
	margin: 50px auto;
    padding: 0 70px;
    min-height: 200px;;
}
.carousel .item {
	color: #999;
	font-size: 16px;
    text-align: center;
	overflow: hidden;
    min-height: 290px;
}
.carousel .item .img-box {
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .img-box img {
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 50%;
}
.carousel .testimonial {
    padding: 30px 0 10px;
    font-style: italic;
    font-size: 18px;
    
}
.carousel .overview {	
	
}
.carousel .overview b {
	text-transform: uppercase;
	color: #7AA641;
}
.carousel .carousel-control {
	width: 40px;
    height: 40px;
    margin-top: -20px;
    top: 50%;
	background: none;
}
.carousel-control i {
    font-size: 68px;
	line-height: 42px;
    position: absolute;
    display: inline-block;
	color: rgba(0, 0, 0, 0.8);
    text-shadow: 0 3px 3px #e6e6e6, 0 0 0 #000;
}
.carousel .carousel-indicators {
	bottom: 5px;
}
.carousel-indicators li, .carousel-indicators li.active {
	width: 10px;
	height: 10px;
	margin: 1px 3px;
	border-radius: 50%;
}
.carousel-indicators li {	
	background: #999;
	border-color: transparent;
	box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
}
.carousel-indicators li.active {	
	background: #555;		
	box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
}


.lib-panel {
    margin-bottom: 20Px;
}
.lib-panel img {
    width: 100%;
    background-color: transparent;
}

.lib-panel .row,
.lib-panel .col-md-6 {
    padding: 0;
    background-color: #FFFFFF;
}


.lib-panel .lib-row {
    padding: 0 20px 0 20px;
}

.lib-panel .lib-row.lib-header {
    background-color: #FFFFFF;
    font-size: 20px;
    padding: 10px 20px 0 20px;
}

.lib-panel .lib-row.lib-header .lib-header-seperator {
    height: 2px;
    width: 26px;
    background-color: #d9d9d9;
    margin: 7px 0 7px 0;
}


.lib-panel .lib-row.lib-desc {
    position: relative;
    height: 100%;
    display: block;
    font-size: 13px;
}
.lib-panel .lib-row.lib-desc a{
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 20px;
}

.row-margin-bottom {
    margin-bottom: 20px;
}

.box-shadow {
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.10);
    box-shadow: 0 0 10px 0 rgba(0,0,0,.10);
}

.no-padding {
    padding: 0;
}



  
  .dd-button {
    display: inline-block;
  
    cursor: pointer;
    white-space: nowrap;
    padding:5px;
  }
  
  .dd-button:after {
   
    border-top: 5px solid black;
  }
  
  .dd-button:hover {
    background-color: #eeeeee;
  }
  
  
  .dd-input {
    display: none;
  }
  
  .dd-menu {
    position: absolute;
    top: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
    background-color: #ffffff;
    list-style-type: none;
  }
  
  .dd-input + .dd-menu {
    display: none;
  } 
  
  .dd-input:checked + .dd-menu {
    display: block;
  } 
  
  .dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .dd-menu li:hover {
    background-color: #f6f6f6;
  }
  
  .dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
  }
  
  .dd-menu li.divider{
    padding: 0;
    border-bottom: 1px solid #cccccc;
  }

  .language
  {
      display: none;
  }


.profile-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #f6f3ee;
    background-clip: border-box;
    border: 1px solid #e2ded7;
    border-radius: .25rem;
    margin-bottom: 15px;

}
.easy-edit-wrapper
{
	color:#fcaf17;
}


        /* style sheet for "A4" printing */
@media print and (width: 21cm) and (height: 29.7cm) {
    @page {
       margin: 3cm;
    }
}

/* style sheet for "letter" printing */
@media print and (width: 8.5in) and (height: 11in) {
   @page {
       margin: 1in;
   }
}

/* A4 Landscape*/
@page {
   size: A4 landscape;
   margin: 10%;
}



.tab-content-sec{
border: 1px solid #efefef;
    margin-left: 0px;
    border-top: none;
    margin-right: 0;
	background:#f7f9fa;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active
{
	background:#62CBD9;
color:#fff;
}

.nav-tabs a:hover
{
	color:#000 !important;
}

.nav-tabs {
    border-bottom: 1px solid #efefef;
}

.heading2
{
	font-size:19px;
}

.tick ul {
  list-style: none;
  font-size:15px;
  list-style-position: outside;
}

.tick2 ul li:before {
  content: '\f00c';
}

.f_item_tick {
  color: #000;
  
}

.course_heading {
    padding-top: 15px;
    padding-bottom: 15px;
    /* font-family: 'Roboto', 'Helvetica Neu'; */
    font-size: 40px;
    color: #000;
    font-weight: 600;
}

.course-feat
{
	padding: 2px 5px;
    border: 0px solid #000;
	font-size:14px;
	margin-right:10px;
	
	color:#f;
}

.yl-label
{
	background: #fcaf17;
    color: #000;
    padding: 2px 6px;
}
.fa-ul
{
	font-size:15px !important;
}
.bl-label
{
	background: #62CBD9;
    color: #fff;
    padding: 2px 6px;
}
.pad5
{
	padding:5px;
}

.outline-box
{
	
	color:#000;
	padding:7px;
	border:1px solid #efefef;
}

.breadcrumb
{
	background:none;
	font-size:13px;
	padding-left:0px;
	margin: 0px;
}

.breadcrumb>li+li:before {
    padding: 0 2px;
    color: #ccc;
    content: ">";
}
	





.course-page-header { 

background: #f6f2ed url(https://easetemplate.com/free-website-templates/hike/images/pageheader.jpg1)no-repeat; position: relative; background-size: cover;
height:400px;
background-repeat: no-repeat;
    background-position: center center;
    border-bottom-width: 0px;
    background-size: cover;
background-image: url('https://educately-resources.s3.ap-south-1.amazonaws.com/web-assets/title-bar-bg.jpg');

 }
.page-caption { padding-top: 12px; padding-bottom: 10px;}
.page-title { font-size: 46px; line-height: 1; color: #fff; font-weight: 600; text-align: center; }

.card-section { position: relative; bottom: 305px; }
.card-block { padding: 80px; }
.section-title { margin-bottom: 60px; }

.border-shadow
{
	box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.nopadding
{
	padding:0px;
}
.c-b-pad
{
	padding:20px 15px 0px 15px;
}

.subheading
{
	margin-top:15px;
	font-weight: 700;
    font-family: 'PTRootUIMedium';
}
.light
{
	color:#8a8a8a;
}
.star-light
{color:#efefef
}

.star-checked {
  color: orange;
}


.ratings .fa-star,.fa-star-half-full
{
	
	color:#fcaf17;
}


.cdivh{ 
    width:100%;
    height:auto;
	
	background-image:  linear-gradient(top, #f6f2ed, #f6f2ed 410px, transparent 410px, transparent 100%);
    background-image:  -webkit-linear-gradient(top, #f6f2ed, #f6f2ed 410px, transparent 410px, transparent 100%)

   
}

@media (max-width: 600px) {
	scroll-x:hidden;
.reactPopup-content
{
	width:90% !important;
	overflow-y:auto !important;
	height:100%;
	overflow-y:auto;
}
.course_topic_heading
{
	font-size:22px;
}
.breadcrumb
{
	float:left !important;
}
.ct_resources
{
	text-align:left;
	padding-bottom:10px;
}

.jumbotron
  {
  padding: 20px 8px;
  }

	

}

@media (max-width: 1200px) {
.activity
{
	width:100%;
}

}



.card-header .title {
    font-size: 17px;
    color: #000;
}
.card-header .accicon {
  float: right;
  font-size: 20px;  
  width: 1.2em;
}
.card-header{
  cursor: pointer;
  border-bottom: none;
}
.card{
  border: 1px solid #ddd;
}
.card-body{
  border-top: 1px solid #ddd;
}
.card-header:not(.collapsed) .rotate-icon {
  transform: rotate(180deg);
}



.collapse
{
	border-bottom: 0px solid #d8d8d8;
}

.heading2 {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 24px;
    color: #3e3e3e;
    font-weight: 600;
}

.video-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  z-index: 10;
}
.video-background{
  position: relative;
}
.play-button2 {   
    cursor: pointer;
    transition: 0.5s;
    opacity: 0.8;
  z-index: 99;
}
.play-button2:hover {
    opacity: 1;
}

.progress {

    background-color: #000000;
 
}


.course-progress
{
	padding:15px;
}


/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

.progress2 {
  width: 150px;
  height: 150px;
  background: none;
  position: relative;
}

.progress2::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}

.progress2>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress2 .progress-left {
  left: 0;
}

.progress2 .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 6px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress2 .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress2 .progress-right {
  right: 0;
}

.progress2 .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progress2 .progress-value {
  position: absolute;
  top: 0;
  left: 0;
}

/*
*
* ==========================================
* FOR DEMO PURPOSE
* ==========================================
*
*/



.rounded-lg {
  border-radius: 1rem;
}

.text-gray {
  color: #aaa;
}

div.h4 {
  line-height: 1rem;
}

.video-title {
    
    border-bottom: 1px solid #ececec;
}
.padt40
{
	padding-top:40px;
}

.comment_details
{
	font-size:15px;
}

.pad15
{
	padding:15px;
}

.qs
{
	font-size:23px;
	font-weight:700;
}
.mb10
{
	margin-bottom:10px;
}
.comdesc
{
	font-size:15px;
}


.topicHeadinginner
{
	padding:5px;
border-bottom:1px solid #efefef;
}

.list-group-item.active {
   
       background-color: #000;
    border-color: #000;
}

.list-group-item-dark {
    color: #fff;
    background-color: #62CBD9;
	
}
.list-group-item-dark a {
    color: #fff;
   
}
.subtipic-list
{
	font-size: 15px;
	border-radius:0px !import;
	
}

.cduration
{
	font-size:12px;
	color: #757575;
}

.list-group-item {
   
    padding: .75rem 0.75rem;
  
}
.blue
{
	color:#62cbd9;
}
.list-group a
{
	color:#000;
}

.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;
    cursor:pointer;
  padding: 9px 16px 9px 42px;
  border: 0px solid #efefef;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .09), 0 1px 1px rgba(0, 0, 0, .45);
  
  color: #757575;
 
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  
  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }
  
  &:active {
    background-color: #eeeeee;
  }
  
  &:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }
  
 
}




.fatopicstatus
{
font-size: 20px;
}

.mobilemenulbl
{
	font-size: 12px;
    margin-top: -12px;
	margin-left: -1px;
}
	
.bg-primaryc
{
	background: #fcaf17;
}

@media print {
  @page { size: landscape; }
}
